/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('#search-toggle').click(function() {
          $('#searchform').toggleClass('display');
        });

        $('#menu-toggle').click(function() {
          $('#mobile-menu').addClass('show');
        });

        $('#mobile-menu a.close').click(function() {
          $('#mobile-menu').removeClass('show');
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        // data-flickity-options='{ "cellAlign": "left", "contain": true, "wrapAround" : true, "autoPlay" : true, "pauseAutoPlayOnHover" : false, "draggable" : false, "cellSelector" : ".carousel-cell", "pageDots" : false }'
        var $carousel = $('#home-banner').flickity({
          cellAlign: "left",
          contain: true,
          wrapAround: true,
          autoPlay: true,
          pauseAutoPlayOnHover: false,
          draggable: false,
          cellSelector: ".carousel-cell",
          pageDots: false
        });
        var flkty = $carousel.data('flickity');
        $('.flickity-page-dots').on( 'click', '.dot', function() {
          $carousel.flickity('stopPlayer');
          var index = $(this).index();
          $carousel.flickity( 'select', index, false, true );
        });
        $carousel.on('cellSelect', function(e) {
          var dot = $('ol.flickity-page-dots .dot').get(flkty.selectedIndex);
          $(dot).addClass('is-selected').siblings().removeClass('is-selected');
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact_us': {
      finalize: function() {
        
      }
    },
    'project_gallery': {
      init: function() {
        $(function() {
          for (var img in magnific) {
            if (magnific.hasOwnProperty(img)) {
              var _items = [];
              for (var i = 0; i < magnific[img].length; i++) {
                var my_item = {src: magnific[img][i].src};

                if (magnific[img][i].hasOwnProperty('caption') && magnific[img][i].caption.length > 0) {
                  my_item.title = magnific[img][i].caption
                  console.log(my_item);
                }
                _items.push(my_item);
              }

              $('#'+img).magnificPopup({
                items: _items.slice(0),
                gallery: {
                    enabled: true
                },
                type: 'image',
                image: {
                markup: '<div class="mfp-figure">'+
                          '<div class="mfp-close"></div>'+
                          '<div class="mfp-img"></div>'+
                          '<div class="mfp-bottom-bar">'+
                            '<div class="mfp-title"></div>'+
                            '<div class="mfp-counter"></div>'+
                          '</div>'+
                        '</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

                cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

                verticalFit: true, // Fits image in area vertically

                tError: '<a href="%url%">The image</a> could not be loaded.' // Error message
                }
              });
            }
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
